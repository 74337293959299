import React, { useState } from 'react';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="bg-white shadow-md">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex">
            <div className="flex-shrink-0 flex items-center">
              <span className="text-2xl font-bold text-gray-900">Marcial Sandoval-Gastelum</span>
            </div>
            <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
              <a
                href="#about"
                className="border-transparent text-[#004182] hover:border-gray-300 hover:text-[#004182] inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
              >
                About
              </a>
              <a
                href="#research"
                className="border-transparent text-[#004182] hover:border-gray-300 hover:text-[#004182] inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
              >
                Research
              </a>
              <a
                href="#contact"
                className="border-transparent text-[#004182] hover:border-gray-300 hover:text-[#004182] inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium"
              >
                Contact
              </a>
            </div>
          </div>

          {/* Hamburger Button */}
          <div className="flex sm:hidden">
            <button
              onClick={toggleMenu}
              type="button"
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#004182]"
              aria-controls="mobile-menu"
              aria-expanded={isOpen}
            >
              <svg
                className="h-6 w-6"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"}
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      {isOpen && (
        <div className="sm:hidden" id="mobile-menu">
          <div className="px-2 pt-2 pb-3 space-y-1">
            <a
              href="#about"
              className="text-[#004182] block px-3 py-2 rounded-md text-base font-medium hover:bg-gray-50"
            >
              About
            </a>
            <a
              href="#research"
              className="text-[#004182] block px-3 py-2 rounded-md text-base font-medium hover:bg-gray-50"
            >
              Research
            </a>
            <a
              href="#contact"
              className="text-[#004182] block px-3 py-2 rounded-md text-base font-medium hover:bg-gray-50"
            >
              Contact
            </a>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;

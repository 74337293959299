// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dot {
  width: 10px;
  height: 10px;
  background-color: #3A7F9B;
  border-radius: 50%;
  opacity: 0;
  animation: wave 1.5s infinite;
}

.dot:nth-child(1) {
  animation-delay: 0s;
}

.dot:nth-child(2) {
  animation-delay: 0.5s;
}

.dot:nth-child(3) {
  animation-delay: 1s;
}

@keyframes wave {
  0% {
    transform: translateY(0);
    opacity: 0.5;
  }
  50% {
    transform: translateY(-10px);
    opacity: 1;
  }
  100% {
    transform: translateY(0);
    opacity: 0.5;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Chatbot.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;EAClB,UAAU;EACV,6BAA6B;AAC/B;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE;IACE,wBAAwB;IACxB,YAAY;EACd;EACA;IACE,4BAA4B;IAC5B,UAAU;EACZ;EACA;IACE,wBAAwB;IACxB,YAAY;EACd;AACF","sourcesContent":[".dot {\n  width: 10px;\n  height: 10px;\n  background-color: #3A7F9B;\n  border-radius: 50%;\n  opacity: 0;\n  animation: wave 1.5s infinite;\n}\n\n.dot:nth-child(1) {\n  animation-delay: 0s;\n}\n\n.dot:nth-child(2) {\n  animation-delay: 0.5s;\n}\n\n.dot:nth-child(3) {\n  animation-delay: 1s;\n}\n\n@keyframes wave {\n  0% {\n    transform: translateY(0);\n    opacity: 0.5;\n  }\n  50% {\n    transform: translateY(-10px);\n    opacity: 1;\n  }\n  100% {\n    transform: translateY(0);\n    opacity: 0.5;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useState } from "react";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [formStatus, setFormStatus] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        "https://marcialsg-api.replit.app/send-email",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );

      const result = await response.json(); // Ensure response is parsed as JSON

      if (result.status === 200) {
        setFormStatus(
          result.message
        );
      } else {
        setFormStatus(
          "There was an error sending your message. Please try again."
        );
      }
    } catch (error) {
      setFormStatus(
        "There was an error sending your message. Please try again."
      );
    }

    // Reset form fields
    setFormData({ name: "", email: "", message: "" });
  };

  return (
    <section id="contact" className="py-20 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2
            className="text-3xl font-extrabold text-gray-900 sm:text-4xl"
            style={{ color: "#004182" }}
          >
            Contact Me
          </h2>
          <p className="mt-4 text-xl text-gray-500">
            Have a question or want to work together?
          </p>
        </div>

        <div className="mt-12 max-w-lg mx-auto">
          <form onSubmit={handleSubmit} className="grid grid-cols-1 gap-6">
            <div>
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                Name
              </label>
              <input
                type="text"
                name="name"
                id="name"
                value={formData.name}
                onChange={handleChange}
                required
                className="mt-1 block w-full shadow-sm sm:text-sm focus:ring-[#004182] focus:border-[#004182] border-gray-300 rounded-md"
              />
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email
              </label>
              <input
                type="email"
                name="email"
                id="email"
                value={formData.email}
                onChange={handleChange}
                required
                className="mt-1 block w-full shadow-sm sm:text-sm focus:ring-[#004182] focus:border-[#004182] border-gray-300 rounded-md"
              />
            </div>
            <div>
              <label
                htmlFor="message"
                className="block text-sm font-medium text-gray-700"
              >
                Message
              </label>
              <textarea
                name="message"
                id="message"
                rows="4"
                value={formData.message}
                onChange={handleChange}
                required
                className="mt-1 block w-full shadow-sm sm:text-sm focus:ring-[#004182] focus:border-[#004182] border-gray-300 rounded-md"
              ></textarea>
            </div>
            <div>
              <button
                type="submit"
                className="w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-[#3A7F9B] hover:bg-[#004182] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#004182]"
              >
                Send Message
              </button>
            </div>
          </form>
          {formStatus && (
            <p className="mt-4 text-sm text-custom-blue">{formStatus}</p>
          )}
        </div>
      </div>
    </section>
  );
};

export default ContactForm;

import React from "react";

const Footer = () => {
  return (
    <footer className="bg-gray-800 text-white py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center">
          <div>
            <h3 className="text-xl font-bold">Marcial Sandoval-Gastelum</h3>
            <p className="mt-2 text-sm">
              Advancing Human Knowledge Through AI Innovation
            </p>
          </div>
          <div className="flex space-x-4">
            <a
              href="https://github.com/marcialsg"
              target="_blank"
              rel="noopener noreferrer"
              className="text-[#004182] hover:text-[#003060]"
            >
              <svg
                fill="#004182"
                className="h-6 w-6"
                viewBox="0 0 16 16"
                xmlns="http://www.w3.org/2000/svg">
                <title>GitHub</title>
                <path
                  d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38v-1.44c-2.22.48-2.69-1.08-2.69-1.08-.36-.92-.88-1.17-.88-1.17-.72-.49.06-.48.06-.48.8.06 1.22.82 1.22.82.71 1.22 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.77-.2-3.64-.88-3.64-3.93 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.22 2.2.82a7.68 7.68 0 012.01-.27c.68 0 1.36.09 2.01.27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.06-1.87 3.73-3.65 3.93.29.25.54.73.54 1.48v2.19c0 .21.15.46.55.38A8.01 8.01 0 0016 8c0-4.42-3.58-8-8-8z" />
              </svg>
            </a>
            <a
              href="http://linkedin.com/in/marcial-sandoval-gastelum-ai"
              target="_blank"
              rel="noopener noreferrer"
              className="text-[#004182] hover:text-[#003060]"
            >
              <svg
                className="h-6 w-6"
                viewBox="0 0 16 16"
                fill="#004182"
                xmlns="http://www.w3.org/2000/svg">
                <title>LinkedIn</title>
                <path
                  d="M15.117 0H.878C.393 0 0 .373 0 .833v14.334c0 .46.392.833.878.833h14.24c.485 0 .878-.373.878-.833V.833c0-.46-.392-.833-.878-.833zM4.773 13.715H2.682V6.132h2.091v7.583zM3.727 5.14h-.014c-.7 0-1.152-.482-1.152-1.086 0-.618.468-1.086 1.181-1.086.714 0 1.152.468 1.166 1.086 0 .604-.452 1.086-1.181 1.086zM13.715 13.715h-2.09v-3.918c0-.985-.353-1.657-1.235-1.657-.673 0-1.074.453-1.25.892-.065.158-.081.378-.081.6v4.083h-2.09s.028-6.626 0-7.583h2.09v1.074c.277-.427.775-1.037 1.884-1.037 1.375 0 2.408.903 2.408 2.843v4.702z" />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

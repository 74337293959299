import React from 'react';

const researchPapers = [
  {
    "title": "Transforming Food and Land Systems to Achieve the SDGs",
    "description": "This study, featured in the Sustainable Development Report 2024, examines adapting food and land systems to achieve Sustainable Development Goals. I contributed to Section 4, analyzing data and creating visualizations from the 2023 ‘Scenathon,’ where researchers from 22 countries collaborated with the FABLE Secretariat to explore three future pathways for food and land-use systems.",
    "imageUrl": "/report_img.PNG",
    "tags": ["Sustainable Development", "Food Systems", "Climate Policy"],
    "url": "https://edepositireland.ie/handle/2262/108572"
  },
  {
    title: "Investigating the Use of Street-Level Imagery and Deep Learning to Produce In-Situ Crop Type Information",
    description: "Research on using street-level imagery and deep learning to gather accurate crop type information, published in Geographies.",
    imageUrl: "/geographies-03-00029-g003.webp", 
    tags: ["Deep Learning", "Remote Sensing", "Agriculture"],
    url: "https://www.mdpi.com/2673-7086/3/3/29" // Add URL for the paper
  },
  {
    title: "BlazePose-Based Action Recognition with Feature Selection Using Stochastic Fractal Search Guided Whale Optimization",
    description: "A study on action recognition using BlazePose and feature selection guided by whale optimization techniques, presented at the ICCAD 2023.",
    imageUrl: "/bp_example.jpg", 
    tags: ["Action Recognition", "Feature Selection", "Optimization"],
    url: "https://ieeexplore.ieee.org/document/10152320" // Add URL for the paper
  }
];

const ResearchPortfolio = () => {
  return (
    <section id="research" className="py-20 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Research Publications
          </h2>
          <p className="mt-4 text-xl text-gray-500">
            An overview of my published research and contributions.
          </p>
        </div>

        <div className="mt-12 grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          {researchPapers.map((paper, index) => (
            <a key={index} href={paper.url} target="_blank" rel="noopener noreferrer" className="bg-white rounded-lg overflow-hidden shadow-lg hover:shadow-xl transition-shadow duration-300">
              <img className="w-full h-48 object-cover" src={paper.imageUrl} alt={paper.title} />
              <div className="p-6">
                <h3 className="text-lg font-medium text-gray-900">{paper.title}</h3>
                <p className="mt-2 text-base text-gray-500">{paper.description}</p>
                <p className="mt-2 text-blue-500">
                  <a href={paper.url} target="_blank" rel="noopener noreferrer" className="underline">[link]</a>
                </p>
                <div className="mt-4 flex flex-wrap">
                  {paper.tags.map((tag, tagIndex) => (
                    <span key={tagIndex} className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">
                      {tag}
                    </span>
                  ))}
                </div>
              </div>
            </a>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ResearchPortfolio;

import React from 'react';
import HeroSection from './components/HeroSection';
import AboutMe from './components/AboutMe';
import ProjectsPortfolio from './components/ProjectsPortfolio';
import ContactForm from './components/ContactForm';
import Chatbot from './components/Chatbot';
import Footer from './components/Footer';
import Navbar from './components/Navbar';

function App() {
  return (
    <div className="App bg-gray-50 min-h-screen">
      <Navbar />
      <main>
        <HeroSection />
        <AboutMe />
        <ProjectsPortfolio />
        <ContactForm />
      </main>
      <Chatbot />
      <Footer />
    </div>
  );
}

export default App;

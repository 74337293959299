import React, { useState, useEffect, useRef } from "react";
import '../Chatbot.css';

const Chatbot = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([
    {
      sender: "bot",
      text: "Hello! I'm Marcial's AI assistant. I'm here to help with any questions you have about his work or research. How can I assist you today?",
    },
  ]);

  const [userMessageInput, setUserMessageInput] = useState("");
  const [isLoading, setIsLoading] = useState(false); // Loader state
  const messagesEndRef = useRef(null);

  // Scroll to the latest message when messages change
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // Handle form submission with fetch API
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!userMessageInput.trim()) return;

    const userMessage = { text: userMessageInput, sender: "user" };
    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setUserMessageInput("");
    setIsLoading(true); // Show loader

    try {
      const response = await fetch(
        "https://marcialsg-api.replit.app/chat",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ message: userMessageInput }),
        }
      );

      const data = await response.json();

      const botMessage = { text: data.response, sender: "bot" };
      setMessages((prevMessages) => [...prevMessages, botMessage]);
    } catch (error) {
      console.error("Error sending message to chatbot:", error);
      const errorMessage = {
        text: "Sorry, I encountered an error. Please try again later.",
        sender: "bot",
      };
      setMessages((prevMessages) => [...prevMessages, errorMessage]);
    } finally {
      setIsLoading(false); // Hide loader
    }
  };

  return (
    <div className="fixed bottom-4 right-4">
      {isOpen ? (
        <div className="bg-white rounded-lg shadow-xl w-80 h-96 flex flex-col">
          <div className="bg-[#004182] text-white p-4 rounded-t-lg flex justify-between items-center">
            <h3 className="text-lg font-semibold">AI Assistant</h3>
            <button
              onClick={() => setIsOpen(false)}
              className="text-white hover:text-gray-200"
            >
              <svg
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div className="flex-1 overflow-y-auto p-4">
            {messages.map((message, index) => (
              <div
                key={index}
                className={`mb-4 ${message.sender === "user" ? "text-right" : "text-left"}`}
              >
                <span
                  className={`inline-block p-2 rounded-lg ${message.sender === "user" ? "bg-[#3A7F9B] text-white" : "bg-gray-200 text-gray-800"}`}
                >
                  {message.text}
                </span>
              </div>
            ))}
            {isLoading && (
              <div className="flex justify-start items-center mb-4 space-x-2">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
              </div>
            )}
            <div ref={messagesEndRef} />
          </div>
          <form onSubmit={handleSubmit} className="p-4 border-t">
            <div className="flex">
              <input
                type="text"
                value={userMessageInput}
                onChange={(e) => setUserMessageInput(e.target.value)}
                placeholder="Type your message..."
                className="flex-1 border rounded-l-lg p-2 focus:outline-none focus:ring-2 focus:ring-[#004182]"
              />
              <button
                type="submit"
                className="bg-[#3A7F9B] text-white px-4 py-2 rounded-r-lg hover:bg-[#004182] transition duration-200"
              >
                Send
              </button>
            </div>
          </form>
        </div>
      ) : (
        <button
          onClick={() => setIsOpen(true)}
          className="bg-[#3A7F9B] text-white p-4 rounded-full shadow-lg hover:bg-[#004182] transition duration-200 my-6 mx-6"
        >
          <svg
            className="h-8 w-8"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"
            />
          </svg>
        </button>
      )}
    </div>
  );
};

export default Chatbot;

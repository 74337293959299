import React, { useState } from 'react';

const HeroSection = () => {
  // State to track if the image is still loading
  const [isLoading, setIsLoading] = useState(true);

  // Function that runs when the image finishes loading
  const handleImageLoad = () => {
    setIsLoading(false); // Set isLoading to false once the image is loaded
  };

  return (
    <section id="hero" className="relative z-0 bg-gray-50 h-screen flex items-center overflow-hidden">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-left">
          <div className="flex flex-col lg:flex-row lg:gap-8 lg:items-center">

            {/* Image Container */}
            <div className="my-8 flex-shrink-0 mb-8 lg:mb-0 lg:mr-8">

              {/* Placeholder: Shows a gray box with a pulsing animation while the image is loading */}


              {/* Actual Image: This image is hidden (opacity-0) until it's fully loaded, then fades in */}
              <img
                src="/profile_pic.jpg"
                alt="Marcial Sandoval-Gastelum"
                className={`
                  w-32 h-32 sm:w-40 sm:h-40 md:w-48 md:h-48 lg:w-56 lg:h-56 xl:w-60 xl:h-60
                  rounded-full shadow-lg object-cover
                  transition-opacity duration-500 ease-in-out 
                  ${isLoading ? 'opacity-0' : 'opacity-100'} 
                `}
                onLoad={handleImageLoad} // Triggers when the image finishes loading
              />
            </div>

            {/* Text Content */}
            <div>
              {/* Heading */}
              <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                <span className="block xl:inline">Welcome</span>
              </h1>

              {/* Description Text */}
              <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg md:mt-5 md:text-xl lg:mx-0">
                My name is Marcial Sandoval-Gastelum, and I’m pleased to have you here. This space aims to showcase my work in using AI for the good of humans. Feel free to explore my projects, publications, and thoughts. I’m always eager to connect and discuss how we can collaborate for a bright future.
              </p>

              {/* Buttons: Two buttons for navigating to different sections */}
              <div className="mt-5 sm:mt-8 sm:flex lg:justify-start">
                {/* Button 1: My Research */}
                <div className="rounded-md shadow">
                  <a
                    href="#research"
                    className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-[#004182] hover:bg-[#002f6c] md:py-4 md:text-lg md:px-10"
                  >
                    My Research
                  </a>
                </div>

                {/* Button 2: Contact Me */}
                <div className="mt-3 sm:mt-0 sm:ml-3">
                  <a
                    href="#contact"
                    className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-[#004182] bg-[#F1F1F1] hover:bg-[#D1D1D1] hover:text-white md:py-4 md:text-lg md:px-10"
                  >
                    Contact Me
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;

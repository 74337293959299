import React from 'react';

const AboutMe = () => {
  return (
    <section id="about" className="py-20 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <h2 className="text-base font-semibold tracking-wide uppercase" style={{ color: '#004182' }}>
            About Me
          </h2>
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            AI as a Tool for the Good of Humanity
          </p>
          <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
            I am a researcher specializing in artificial intelligence. In recent years, my focus has been on developing deep learning architectures to address climate change. With over six years of experience, I have collaborated with institutions such as IIASA and UCL to make use of AI in solving critical challenges.
          </p>
          <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
            I am a passionate advocate for AI and its potential to transform our world. I believe that AI is key to solving climate change and many other existential problems we face today. However, I am also aware that this AI revolution may lead to unintended consequences. I am committed to ensuring that AI is used responsibly and ethically. One of the most significant dangers of AI is the overconfidence that people may place in machines.  Please use these tools responsibly and always verify the outcomes before sharing them with the public.
          </p>

        </div>

        <div className="mt-10">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
            <div className="relative">
              <dt>
                <div className="absolute flex items-center justify-center h-12 w-12 rounded-md" style={{ backgroundColor: '#004182' }}>
                  <svg className="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 20.5c-3.27 0-5.5-2.23-5.5-5.5S8.73 9.5 12 9.5s5.5 2.23 5.5 5.5-2.23 5.5-5.5 5.5zM12 7a4 4 0 100 8 4 4 0 000-8zM1 12c0-6.08 4.92-11 11-11s11 4.92 11 11-4.92 11-11 11S1 18.08 1 12z" />
                  </svg>
                </div>
                <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Human Action Recognition</p>
              </dt>
              <dd className="mt-2 ml-16 text-base text-gray-500">
                Experience in developing and applying human action recognition systems using inertial data and graph convolutional networks. Contributed to research projects at IIASA and UCL to enhance the accuracy and efficiency of action recognition models.
              </dd>
            </div>

            <div className="relative">
              <dt>
                <div className="absolute flex items-center justify-center h-12 w-12 rounded-md" style={{ backgroundColor: '#004182' }}>
                  <svg className="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 20.5c-3.27 0-5.5-2.23-5.5-5.5S8.73 9.5 12 9.5s5.5 2.23 5.5 5.5-2.23 5.5-5.5 5.5zM12 7a4 4 0 100 8 4 4 0 000-8zM1 12c0-6.08 4.92-11 11-11s11 4.92 11 11-4.92 11-11 11S1 18.08 1 12z" />
                  </svg>
                </div>
                <p className="ml-16 text-lg leading-6 font-medium text-gray-900">Climate Change Research</p>
              </dt>
              <dd className="mt-2 ml-16 text-base text-gray-500">
                Focused on innovative solutions and models to address climate change, including multi-agent reinforcement learning algorithms within the FABLE consortium.
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </section>
  );
};

export default AboutMe;
